<template>
	<div style="height: 100%;">
		<main-table>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-date-picker v-model="params.month" type="month" value-format="yyyy-MM" placeholder="选择结算月份"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-cascader style="width: 100%;" placeholder="请选择所属部门" v-model="params.departments" :options="tree_departments" :props="{expandTrigger: 'hover', value: 'unique_id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.create_id" placeholder="请选择创建人" filterable multiple collapse-tags>
						<el-option label="系统" :value="0"></el-option>
						<el-option v-for="employee in employees" :key="employee.id" :label="employee.name+(employee.separated_at ? '(已离职)' : '')" :value="employee.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.employee_id" placeholder="请选择员工" filterable multiple collapse-tags>
						<el-option v-for="employee in employees" :key="employee.id" :label="employee.name+(employee.separated_at ? '(已离职)' : '')" :value="employee.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.salary_field_id" placeholder="请选择字段" filterable multiple collapse-tags>
						<el-option v-for="column in fields" :key="column.id" :label="column.name" :value="column.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getDetails({ ...params, page: 1})">查询</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" class="scroll-wrapper absolute" height="100%" :data="details" :size="theme.size">
				<el-table-column label="创建人" min-width="90" :formatter="({employee}) => { return employee ? employee.name : '系统'; }"></el-table-column>
				<el-table-column label="员工" min-width="90" :formatter="({salary}) => { return salary.employee ? salary.employee.name : '/'; }"></el-table-column>
				<el-table-column label="字段" prop="salary_field.name" min-width="50"></el-table-column>
				<el-table-column label="数值" min-width="60" :formatter="({amount}) => { return +amount; }"></el-table-column>
				<el-table-column label="备注说明" prop="commit" min-width="140" show-overflow-tooltip></el-table-column>
				<el-table-column label="结算月份" align="center" min-width="80">
					<template slot-scope="scope">{{scope.row.salary.created_at | format_at}}</template>
				</el-table-column>
				<el-table-column label="更新时间" prop="updated_at" min-width="150"></el-table-column>
				<el-table-column label="创建时间" prop="created_at" min-width="150"></el-table-column>
				<el-table-column label="操作" min-width="50">
					<el-link slot-scope="scope" type="primary" @click="delRow(scope.row)">删除</el-link>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getDetails({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getDetails({ ...params, page: v }) }"
				background>
			</el-pagination>
		</main-table>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import moment from 'moment';
	import mainTable from '@/components/main-table';

	export default {
		components: {
			mainTable,
		},
		computed: {
			...mapState(['theme', 'oa_departments']),
			tree_departments () {
				return this.$utils.buildTree(Object.values(this.oa_departments));
			}
		},
		filters: {
			format_at (t) {
				if (!t) return '无';
				return moment(t).format('Y-MM');
			}
		},
		methods: {
			delRow ({id, name}) {
				this.$confirm(name, '确定要删除吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleDetails({action: 'delete', id});
				});
			},
			async handleDetails (params) {
				const res = await this.$http.get(this.$api.URI_SALARIES_DETAILS, {params, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getDetails(this.params);
				this.$message.success(msg);
			},
			async getDetails (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_SALARIES_DETAILS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.details = result.data;
				this.fields = result.fields;
				this.employees = result.employees;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				employees: [],
				fields: [],
				details: [],
				details_dialog: false,
				params: {
					perPage: 10
				}
			}
		},
		async created () {
			this.getDetails(this.params, true);
		}
	};
</script>